.special {
    font-family: 'Cabin', sans-serif;
}

.fredka {
    font-family: 'Fredoka One', cursive;
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: #1b1d21;
}

.card {
    font-family: 'Cabin', sans-serif;
    font-family: 'Quicksand', sans-serif;
}

.nav-bg {
    background-color: #131017;
}

.sidebar-bg {
    background-color: #19171d;
}

.body-bg {
    background-color: #1b1d21;
}

input:disabled {
    background: black;
    opacity: .4;
    color: white;
    border: #19171d;
}

.animation {
    animation: .3s slidein ease-in-out;
}
  
@keyframes slidein {
    from {
        top: -200px;
        opacity: 0
    }

    to {
        top: 10px;
        opacity: 1  
    }
}
@import
url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
}
*{
	margin:0;
	padding:0;
	box-sizing: border-box;
}
.container{
	max-width: 1170px;
	margin:auto;
}
.row{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
}
.footer{
	background-color: #24262b;
    padding: 70px 0;
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: rgb(147 197 253);
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li a{
	font-size: 16px;
	/* text-transform: capitalize; */
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}
